<div
    class="app-user-form"
    ngModelGroup="app-details"
    id="userForm"
    *ngIf="user"
    [ngClass]="isEditMode ? 'editing' : isCreateMode ? 'creating' : 'viewing'"
>
    <!--- CREATE / EDIT --->
    <div *ngIf="(isCreateMode || isEditMode) && !isRetired">
        <!--- SECTION 1 --->
        <div>
            <div class="form-group">
                <div class="form-entry">
                    <!--- First Name --->
                    <multi-locale-input
                        #firstName="ngModel"
                        name="firstName"
                        [(ngModel)]="user.first_names"
                        [inputLabel]="firstnameLabel"
                        [locales]="multiLocaleConfig.supportedLocales"
                        [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                        [localeKey]="multiLocaleConfig.localeKey"
                        [defaultLocale]="multiLocaleConfig.defaultLocale"
                        [placeholderText]="firstnamePlaceholder"
                        [required]="true"
                        [id]="'user_first_name'"
                        data-automation="firstName"
                    >
                    </multi-locale-input>
                </div>

                <div class="form-entry">
                    <!--- Last Name --->
                    <multi-locale-input
                        #lastName="ngModel"
                        name="lastName"
                        [(ngModel)]="user.last_names"
                        [inputLabel]="lastnameLabel"
                        [locales]="multiLocaleConfig.supportedLocales"
                        [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                        [localeKey]="multiLocaleConfig.localeKey"
                        [defaultLocale]="multiLocaleConfig.defaultLocale"
                        [placeholderText]="lastnamePlaceholder"
                        [required]="true"
                        [id]="'user_last_name'"
                        data-automation="lastName"
                    >
                    </multi-locale-input>
                </div>

                <div class="form-entry">
                    <!--- Username --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.user_name' | translate }}</mat-label>
                        <input
                            matInput
                            id="username"
                            #username="ngModel"
                            type="text"
                            class="input-entry"
                            maxlength="70"
                            [ngClass]="{ failure: username.invalid && (username.dirty || username.touched) }"
                            placeholder="{{ 'user.create.placeholder.user_name' | translate }}"
                            [disabled]="isEditMode"
                            name="username"
                            [(ngModel)]="user.username"
                            data-url-persist
                            required
                            minlength="8"
                            data-automation="username"
                            matTooltip="{{ 'user.tooltip.username' | translate }}"
                        />

                        <mat-icon
                            svgIcon="general-info"
                            data-automation="usernameHelpIcon"
                            matSuffix
                            class="tooltip-icon mat-select-tooltip"
                            matTooltip="{{ 'user.tooltip.username' | translate }}"
                        ></mat-icon>

                        <mat-error *ngIf="username.invalid && (username.dirty || username.touched)">
                            <mat-error *ngIf="username.errors.required">
                                <span>{{ 'user.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="username.errors.minlength">
                                <span>{{ 'user.validation.minLength' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- User Type --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.user_type' | translate }}</mat-label>
                        <mat-select
                            id="userType"
                            name="userTypeId"
                            #userTypeId="ngModel"
                            class="input-entry create-select"
                            (selectionChange)="onUserTypeFieldChange()"
                            [class.loading-field]="loadingUserTypes"
                            [ngClass]="{ failure: userTypeId.invalid && (userTypeId.dirty || userTypeId.touched) }"
                            [(ngModel)]="user.userType"
                            [disabled]="isEditMode"
                            data-automation="userTypeSelector"
                            data-url-persist
                            required
                            placeholder="{{
                                (userTypes && userTypes.length > 0
                                    ? 'user.create.placeholder.usertype_selector'
                                    : 'user.create.placeholder.no_usertypes'
                                ) | translate
                            }}"
                        >
                            <mat-option *ngFor="let userType of filteredUserTypes" [value]="userType.name">
                                {{ userType.display_label }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="userTypeId.invalid && (userTypeId.dirty || userTypeId.touched)">
                            <mat-error *ngIf="userTypeId.errors.required">
                                {{ 'user.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                        <mat-hint *ngIf="loadingUserTypes" align="end">{{ 'user.loading' | translate }}</mat-hint>
                        <mat-spinner *ngIf="loadingUserTypes" matSuffix diameter="23"></mat-spinner>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- User Title --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.title' | translate }}</mat-label>
                        <input
                            matInput
                            id="title"
                            #title="ngModel"
                            type="text"
                            class="input-entry"
                            [ngClass]="{ failure: title.invalid && (title.dirty || title.touched) }"
                            name="title"
                            placeholder="{{ 'user.create.placeholder.title' | translate }}"
                            maxlength="80"
                            [(ngModel)]="user.title"
                            data-automation="title"
                            data-url-persist
                        />
                        <mat-error *ngIf="title.invalid && (title.dirty || title.touched)">
                            <mat-error *ngIf="title.errors.required">
                                <span>{{ 'user.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- supported locales --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.supported_locales' | translate }}</mat-label>
                        <mat-select
                            id="supportedLocale"
                            name="supportedLocale"
                            #supportedLocale="ngModel"
                            class="input-entry create-select"
                            [ngClass]="{
                                failure: supportedLocale.invalid && (supportedLocale.dirty || supportedLocale.touched)
                            }"
                            [(ngModel)]="multiLocaleConfig.supportedLocales"
                            (selectionChange)="onSupportedLocaleChange()"
                            placeholder="{{ 'user.create.placeholder.supported_locales' | translate }}"
                            data-automation="locale"
                            required
                            multiple
                            matTooltip="{{ 'user.tooltip.supported_locales' | translate }}"
                        >
                            <mat-option
                                *ngFor="let locale of locales"
                                [disabled]="limitLocaleSelectList(5, locale)"
                                [value]="locale"
                                >{{ locale.displayLabel }}</mat-option
                            >
                        </mat-select>
                        
                        <mat-icon
                            svgIcon="general-info"
                            data-automation="supportedLocalesHelpIcon"
                            matSuffix
                            class="tooltip-icon mat-select-tooltip"
                            matTooltip="{{ 'user.tooltip.supported_locales' | translate }}"
                        ></mat-icon>
                        
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- default locale --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.locale' | translate }}</mat-label>
                        <mat-select
                            id="defaultLocale"
                            name="defaultLocale"
                            #defaultLocale="ngModel"
                            class="input-entry create-select"
                            [ngClass]="{
                                failure: defaultLocale.invalid && (defaultLocale.dirty || defaultLocale.touched)
                            }"
                            [(ngModel)]="user.locale"
                            placeholder="{{ 'user.create.placeholder.locale_selector' | translate }}"
                            data-automation="locale"
                            required
                            matTooltip="{{ 'user.tooltip.locale' | translate }}"
                        >
                            <mat-option
                                *ngFor="let locale of multiLocaleConfig.supportedLocales"
                                [value]="locale.localeName"
                                >{{ locale.displayLabel }}</mat-option
                            >
                        </mat-select>

                        <mat-icon
                            svgIcon="general-info"
                            data-automation="localeHelpIcon"
                            matSuffix
                            class="tooltip-icon mat-select-tooltip"
                            matTooltip="{{ 'user.tooltip.locale' | translate }}"
                        ></mat-icon>

                        <mat-error *ngIf="defaultLocale.invalid">
                            <mat-error *ngIf="defaultLocale.errors.pattern">
                                {{ 'user.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Brand --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.brand' | translate }}</mat-label>
                        <mat-select
                            id="brand"
                            name="brand"
                            #brand="ngModel"
                            class="input-entry create-select"
                            [class.loading-field]="loadingBrands"
                            [ngClass]="{ failure: brand.invalid && (brand.dirty || brand.touched) }"
                            [disabled]="isEditMode || !userTypeId.dirty || filteredBrands?.length === 1"
                            [(ngModel)]="user.brand"
                            data-automation="brandSelector"
                            data-url-persist
                            required
                            placeholder="{{
                                (brands && brands.length > 0
                                    ? 'user.create.placeholder.brand_selector'
                                    : 'user.create.placeholder.no_brands'
                                ) | translate
                            }}"
                            matTooltip="{{ 'user.tooltip.brand' | translate }}"
                        >
                            <mat-option *ngFor="let brand of filteredBrands" [value]="brand.name">
                                {{ brand.displayLabel }}
                            </mat-option>
                        </mat-select>

                        <mat-icon
                            svgIcon="general-info"
                            data-automation="brandHelpIcon"
                            matSuffix
                            class="tooltip-icon mat-select-tooltip"
                            matTooltip="{{ 'user.tooltip.brand' | translate }}"
                        ></mat-icon>

                        <mat-error *ngIf="brand.invalid && (brand.dirty || brand.touched)">
                            <mat-error *ngIf="brand.errors.required">
                                {{ 'user.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                        <mat-hint *ngIf="loadingBrands" align="end">{{ 'user.loading' | translate }}</mat-hint>
                        <mat-spinner *ngIf="loadingBrands" matSuffix diameter="23"></mat-spinner>
                    </mat-form-field>
                </div>

                <div class="form-entry" >
                    <!--- User Class --->
                    <mat-checkbox
                        name="system_level"
                        data-automation="system_level"
                        id="system_level"
                        [checked]="user.userClass === 'SYSTEM'"
                        (change)="onSystemLevelChange($event)"
                        [disabled]="!isCreateMode"
                        >
                        {{ 'user.system_user' | translate }}
                    </mat-checkbox>
                </div>

                <div *ngIf="!isCreateMode" class="form-entry">
                    <!--- User Contact ID --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.contact_id' | translate }}</mat-label>
                        <input
                            matInput
                            id="flex_contact_id"
                            type="text"
                            class="input-entry"
                            name="flex_contact_id"
                            [(ngModel)]="user.flex_contact_id"
                            data-automation="flex_contact_id"
                            data-url-persist
                            disabled
                            matTooltip="{{ user.flex_contact_id }}"
                        />
                    </mat-form-field>
                </div>
            </div>

            <div class="button-container">
                <div class="right-button">
                    <button class="icon-btn x-btn-secondary squared" (click)="collapseAll()">
                        <mat-icon>expand_less</mat-icon>
                        <span class="btn-text">{{ 'user.collapse_all' | translate }}</span>
                    </button>
                </div>
                <div class="right-button">
                    <button class="icon-btn x-btn-secondary squared" (click)="expandAll()">
                        <mat-icon>expand_more</mat-icon>
                        <span class="btn-text">{{ 'user.expand_all' | translate }}</span>
                    </button>
                </div>
            </div>
        </div>

        <mat-expansion-panel
            togglePosition="before"
            data-automation="contact_information_panel"
            [expanded]="collapseArray[0]"
            (opened)="expansionOpened(0)"
            (closed)="expansionClosed(0)"
        >
            <mat-expansion-panel-header
                class="panel-override"
                collapsedHeight="54px"
                expandedHeight="54px"
                data-automation="contact_information_header"
            >
                <mat-panel-title>
                    <h4 class="card-title" data-automation="contact_information">
                        {{ 'user.contact_information' | translate }}
                    </h4>
                </mat-panel-title>
                <mat-panel-description class="contact_information_subtitle"> </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="form-group">
                <div>
                    <div class="form-entry column-3">
                        <!--- Email --->
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'user.email' | translate }}</mat-label>
                            <input
                                matInput
                                id="email"
                                #email="ngModel"
                                type="email"
                                class="input-entry"
                                data-automation="email"
                                [ngClass]="{ failure: email.invalid && (email.dirty || email.touched) }"
                                name="email"
                                placeholder="{{ 'user.create.placeholder.email' | translate }}"
                                maxlength="100"
                                [(ngModel)]="user.email"
                                data-url-persist
                                required
                                pattern="^([A-Za-z0-9_\-\'\.]+)@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z0-9-]{2,9})$"
                            />
                            <mat-error
                                *ngIf="role_error_name == null && email.invalid && (email.dirty || email.touched)"
                            >
                                <mat-error *ngIf="email.errors.required">
                                    <span>{{ 'user.validation.required' | translate }}</span>
                                </mat-error>
                                <mat-error *ngIf="email.errors.pattern">
                                    <span>{{ 'user.validation.pattern' | translate }}</span>
                                </mat-error>
                            </mat-error>
                            <mat-error
                                *ngIf="
                                    role_error_name == 'email notification' &&
                                    email.invalid &&
                                    (email.dirty || email.touched)
                                "
                            >
                                <mat-error *ngIf="email.errors.required">
                                    <span>{{
                                        'user.validation.roleVerification' | translate : { roleName: role_error_name }
                                    }}</span>
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Country calling code -->
                <div class="form-entry column-3">
                    <!--- Country Calling Codes --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.countryCode' | translate }}</mat-label>
                        <mat-select
                            id="countryCode"
                            name="countryCode"
                            #countryCode="ngModel"
                            (selectionChange)="onPhoneFieldChange($event)"
                            class="input-entry create-select"
                            [ngClass]="{ failure: countryCode.invalid && (countryCode.dirty || countryCode.touched) }"
                            [(ngModel)]="user.phones[0] && user.phones[0].country_code"
                            data-automation="countryCode"
                            placeholder="{{
                                (phoneFieldValidation
                                    ? 'user.create.placeholder.country_code_required'
                                    : 'user.create.placeholder.country_code'
                                ) | translate
                            }}"
                        >
                            <mat-option>{{ 'user.create.placeholder.country_code' | translate }}</mat-option>
                            <mat-option *ngFor="let country of countries" [value]="country.callingCodes[0]">
                                +{{ country.callingCodes[0] }} {{ country.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="countryCode.invalid && (countryCode.dirty || countryCode.touched)">
                            <mat-error *ngIf="countryCode.errors.required">
                                {{ 'user.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                        <mat-error
                            *ngIf="role_error_name && countryCode.invalid && (countryCode.dirty || countryCode.touched)"
                        >
                            <mat-error *ngIf="countryCode.errors.required">
                                <span>{{
                                    'user.validation.roleVerification' | translate : { roleName: role_error_name }
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry column-3 label-info">
                    <!--- Phone Number --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.phone_number' | translate }}</mat-label>
                        <input
                            matInput
                            id="phone_number"
                            #phoneNumber="ngModel"
                            (input)="onPhoneFieldChange($event)"
                            class="input-entry"
                            type="text"
                            [ngClass]="{ failure: phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched) }"
                            name="phoneNumber"
                            data-automation="phoneNumber"
                            placeholder="{{
                                (phoneFieldValidation
                                    ? 'user.create.placeholder.phone_number_required'
                                    : 'user.create.placeholder.phone_number'
                                ) | translate
                            }}"
                            [(ngModel)]="user.phones[0] && user.phones[0].phone_number"
                            data-url-persist
                            pattern=""
                            matTooltip="{{ 'user.tooltip.phone_number' | translate }}"
                        />
                        <mat-icon
                            svgIcon="general-info"
                            data-automation="phoneIcon"
                            matSuffix
                            class="tooltip-icon"
                            matTooltip="{{ 'user.tooltip.phone_number' | translate }}"
                        ></mat-icon>
                        <mat-error
                            *ngIf="
                                role_error_name == null &&
                                phoneNumber.invalid &&
                                (phoneNumber.dirty || phoneNumber.touched)
                            "
                        >
                            <mat-error *ngIf="phoneNumber.errors.required">
                                <span>{{ 'user.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="phoneNumber.errors.pattern">
                                <span>{{ 'user.validation.phone.pattern' | translate }}</span>
                            </mat-error>
                        </mat-error>
                        <mat-error
                            *ngIf="
                                role_error_name == 'voice notification' &&
                                hasAnyMobilePhoneField == false &&
                                phoneNumber.invalid &&
                                (phoneNumber.dirty || phoneNumber.touched)
                            "
                        >
                            <mat-error *ngIf="phoneNumber.errors.required">
                                <span>{{
                                    'user.validation.roleVerification' | translate : { roleName: role_error_name }
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry column-3">
                    <!--- Phone Extension --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.phone_extension' | translate }}</mat-label>
                        <input
                            matInput
                            id="phone_extension"
                            #phoneExtension="ngModel"
                            class="input-entry"
                            (input)="onPhoneFieldChange($event)"
                            type="text"
                            [ngClass]="{
                                failure: phoneExtension.invalid && (phoneExtension.dirty || phoneExtension.touched)
                            }"
                            name="phoneExtension"
                            data-automation="phoneExtension"
                            placeholder="{{ 'user.create.placeholder.extension' | translate }}"
                            [(ngModel)]="user.phones[0] && user.phones[0].extension"
                            maxlength="9"
                            data-url-persist
                            pattern=""
                        />
                        <mat-error
                            *ngIf="
                                role_error_name == null &&
                                phoneExtension.invalid &&
                                (phoneExtension.dirty || phoneExtension.touched)
                            "
                        >
                            <mat-error *ngIf="phoneExtension.errors.required">
                                <span>{{ 'user.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="phoneExtension.errors.pattern">
                                <span>{{ 'user.validation.phone.pattern' | translate }}</span>
                            </mat-error>
                        </mat-error>
                        <mat-error
                            *ngIf="
                                role_error_name == 'voice notification' &&
                                hasAnyMobilePhoneField == false &&
                                phoneExtension.invalid &&
                                (phoneExtension.dirty || phoneExtension.touched)
                            "
                        >
                            <mat-error *ngIf="phoneExtension.errors.required">
                                <span>{{
                                    'user.validation.roleVerification' | translate : { roleName: role_error_name }
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--  Mobile Country calling code -->
                <div class="form-entry column-3">
                    <!--- Country Calling Codes --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.mobileCountryCode' | translate }}</mat-label>
                        <mat-select
                            id="mobileCountryCode"
                            name="mobileCountryCode"
                            #mobileCountryCode="ngModel"
                            (selectionChange)="onMobilePhoneFieldChange($event)"
                            class="input-entry create-select"
                            [ngClass]="{
                                failure:
                                    mobileCountryCode.invalid && (mobileCountryCode.dirty || mobileCountryCode.touched)
                            }"
                            [(ngModel)]="user.phones[1] && user.phones[1].country_code"
                            data-automation="countryCode"
                            placeholder="{{
                                (mobilePhoneFieldValidation
                                    ? 'user.create.placeholder.country_code_required'
                                    : 'user.create.placeholder.country_code'
                                ) | translate
                            }}"
                        >
                            <mat-option>{{ 'user.create.placeholder.country_code' | translate }}</mat-option>
                            <mat-option *ngFor="let country of countries" [value]="country.callingCodes[0]">
                                +{{ country.callingCodes[0] }} {{ country.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="mobileCountryCode.invalid && (mobileCountryCode.dirty || mobileCountryCode.touched)"
                        >
                            <mat-error *ngIf="mobileCountryCode.errors.required">
                                {{ 'user.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                        <mat-error
                            *ngIf="
                                role_error_name &&
                                mobileCountryCode.invalid &&
                                (mobileCountryCode.dirty || mobileCountryCode.touched)
                            "
                        >
                            <mat-error *ngIf="mobileCountryCode.errors.required">
                                <span>{{
                                    'user.validation.roleVerification' | translate : { roleName: role_error_name }
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry column-3 label-info">
                    <!--- Mobile Phone Number --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.mobile_phone_number' | translate }}</mat-label>
                        <input
                            matInput
                            id="mobile_phone_number"
                            #mobilePhoneNumber="ngModel"
                            (input)="onMobilePhoneFieldChange($event)"
                            class="input-entry"
                            type="text"
                            [ngClass]="{
                                failure:
                                    mobilePhoneNumber.invalid && (mobilePhoneNumber.dirty || mobilePhoneNumber.touched)
                            }"
                            name="mobilePhoneNumber"
                            data-automation="mobilePhoneNumber"
                            placeholder="{{
                                (mobilePhoneFieldValidation
                                    ? 'user.create.placeholder.phone_number_required'
                                    : 'user.create.placeholder.phone_number'
                                ) | translate
                            }}"
                            [(ngModel)]="user.phones[1] && user.phones[1].phone_number"
                            data-url-persist
                            pattern=""
                            matTooltip="{{ 'user.tooltip.mobile_phone_number' | translate }}"
                        />
                        <mat-icon
                            svgIcon="general-info"
                            data-automation="mobilePhoneIcon"
                            matSuffix
                            class="tooltip-icon"
                            matTooltip="{{ 'user.tooltip.mobile_phone_number' | translate }}"
                        ></mat-icon>
                        <mat-error
                            *ngIf="
                                (role_error_name == 'voice notification' || role_error_name == null) &&
                                mobilePhoneNumber.invalid &&
                                (mobilePhoneNumber.dirty || mobilePhoneNumber.touched)
                            "
                        >
                            <mat-error *ngIf="mobilePhoneNumber.errors.required">
                                <span>{{ 'user.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="mobilePhoneNumber.errors.pattern">
                                <span>{{ 'user.validation.phone.pattern' | translate }}</span>
                            </mat-error>
                        </mat-error>
                        <mat-error
                            *ngIf="
                                role_error_name == 'sms notification' &&
                                mobilePhoneNumber.invalid &&
                                (mobilePhoneNumber.dirty || mobilePhoneNumber.touched)
                            "
                        >
                            <mat-error *ngIf="mobilePhoneNumber.errors.required">
                                <span>{{
                                    'user.validation.roleVerification' | translate : { roleName: role_error_name }
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-entry-column-checks">
                    <!--- primary_contact --->
                    <div class="form-entry">
                        <span>
                            <mat-checkbox
                            name="primaryContact"
                            data-automation="primary_contact"
                            #primaryContact="ngModel"
                            id="primary_contact"
                            [(ngModel)]="user.primary_contact"
                            matTooltip="{{ 'user.tooltip.primary_contact' | translate }}"
                            >
                                {{ 'user.primary_contact' | translate }}
                            </mat-checkbox>
                            <mat-icon
                                svgIcon="general-info"
                                data-automation="primaryContactHelpIcon"
                                matSuffix
                                class="tooltip-icon mat-select-tooltip"
                                matTooltip="{{ 'user.tooltip.primary_contact' | translate }}"
                            ></mat-icon>
                        </span>
                    </div>

                    <!--- twentyfour_seven_contact --->
                    <div class="form-entry">
                        <span>
                            <mat-checkbox
                            name="twentyFourSevenContact"
                            data-automation="twentyfour_seven_contact"
                            #twentyFourSevenContact="ngModel"
                            id="twentyfour_seven_contact"
                            [(ngModel)]="user.twentyfour_seven_contact"
                            matTooltip="{{ 'user.tooltip.24_7_contact' | translate }}"
                            >{{ 'user.24_7_contact' | translate }}</mat-checkbox
                            >
                            <mat-icon
                                svgIcon="general-info"
                                data-automation="twentyFourSevenContactHelpIcon"
                                matSuffix
                                class="tooltip-icon mat-select-tooltip"
                                matTooltip="{{ 'user.tooltip.24_7_contact' | translate }}"
                            ></mat-icon>
                    </span>
                    </div>
                </div>
            </div>

            <div class="form-group table-container" *ngIf="isEditMode">
                <app-subscription-table 
                    mode="edit" [userId]="userId"
                    [hasEmailField]="user.email !== '' && user.email !== null && user.email !== undefined"
                    [hasAnyPhoneField]="user && user.phones && user.phones[0].phone_number"
                    [hasAnyMobilePhoneField]="user && user.phones && user.phones[1].phone_number"
                    (addSubscriptionEvent)="addSubscription($event)"
                    (delSubscriptionEvent)="removeSubscription($event)"
                ></app-subscription-table>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel
            togglePosition="before"
            data-automation="user_roles_panel"
            *ngIf="isEditMode && isNotOwnProfile()"
            [expanded]="collapseArray[1]"
            (opened)="expansionOpened(1)"
            (closed)="expansionClosed(1)"
        >
            <mat-expansion-panel-header
                class="panel-override"
                collapsedHeight="54px"
                expandedHeight="54px"
                data-automation="user_roles_header"
            >
                <mat-panel-title>
                    <h4 class="card-title" data-automation="user_roles">{{ 'user.user_roles' | translate }}</h4>
                </mat-panel-title>
                <mat-panel-description class="user_roles_subtitle"> </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="form-group">
                <ng-container>
                    <h4 class="form-header">{{ 'user.edit.location_roles' | translate }}</h4>
                    <app-location-roles
                        [userId]="userId"
                        [hasAnyAddressField]="savedUser && savedUser.addresses && savedUser.addresses.length > 0"
                        [hasAnyPhoneField]="savedUser && savedUser.phones && savedUser.phones[0]"
                        [hasAnyMobilePhoneField]="savedUser && savedUser.phones && savedUser.phones[1]"
                        [hasEmailField]="user.email !== '' && user.email !== null && user.email !== undefined"
                        [hasEcrm]="user.sync_to_ecrm"
                        (applyingRolesEvent)="applyRoles($event)"
                        (roleError)="roleError($event)"
                        (onRoleAdd)="onRoleAdd()"
                    >
                    </app-location-roles>
                </ng-container>

                <!-- <h4 class="form-header-no-border">{{ 'user.edit.active_location_roles' | translate }}</h4> -->
                <div class="form-header-container">
                    <h4 class="form-header-no-border">{{ 'user.edit.active_location_roles' | translate }}</h4>
                    <span><span>{{ "user.edit.roles_added" | translate }} {{addedRoles}}</span><span class="ml-1">{{ "user.edit.roles_deleted" | translate }} {{removedRoles}}</span></span>
                </div>
                <app-active-location-roles
                    [mode]="mode"
                    [changed]="addedRoles > 0 || removedRoles > 0"
                    [hasEcrm]="user.ecrm_id !== '' && user.ecrm_id !== null && user.ecrm_id !== undefined && user.ecrm_id.length == 18" 
                    (applyingRolesEvent)="applyRoles($event)"
                    (onRoleRemoval)="onRoleRemoval($event)"
                    (unRemoveRoleEvent)="unRemoveRole($event)"
                    (onSiteRemoval)="onSiteRemoval($event)"
                    (onRoleUpdate)="newSubmitRoles()"
                ></app-active-location-roles>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel
            togglePosition="before"
            data-automation="sync_information_panel"
            [expanded]="collapseArray[2]"
            (opened)="expansionOpened(2)"
            (closed)="expansionClosed(2)"
        >
            <mat-expansion-panel-header
                class="panel-override"
                collapsedHeight="54px"
                expandedHeight="54px"
                data-automation="sync_information_header"
            >
                <mat-panel-title>
                    <h4 class="card-title" data-automation="sync_information">
                        {{ 'user.sync_information' | translate }}
                    </h4>
                </mat-panel-title>
                <mat-panel-description class="sync_information_subtitle"> </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="form-group">
                <div class="form-entry" *ngIf="isEditMode">
                    <!--- ECRM Contact ID --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.ecrm_contact_id' | translate }}</mat-label>
                        <input
                            matInput
                            id="contact_id"
                            #contactID="ngModel"
                            type="text"
                            class="input-entry"
                            [ngClass]="{ failure: contactID.invalid && (contactID.dirty || contactID.touched) }"
                            placeholder="{{ 'user.ecrm_contact_id' | translate }}"
                            name="contactId"
                            [(ngModel)]="user.contact_id"
                            data-url-persist
                            data-automation="contact_id"
                            [disabled]="!user.sync_to_ecrm"
                            (input)="onECRMContactIDChange()"
                            matTooltip="{{ 'user.tooltip.ecrm_contact_id' | translate }}"
                        />
                        <mat-icon
                            svgIcon="general-info"
                            data-automation="ecrmContactHelpIcon"
                            matSuffix
                            class="tooltip-icon mat-select-tooltip"
                            matTooltip="{{ 'user.tooltip.ecrm_contact_id' | translate }}"
                        ></mat-icon>
                        <mat-error *ngIf="contactID.invalid && (contactID.dirty || contactID.touched)">
                            <mat-error *ngIf="contactID.errors.pattern">
                                <span data-automation="ecrmContactIDLengthError"
                                    >{{ 'user.validation.ecrm.contact_id_length' | translate }}
                                </span>
                            </mat-error>
                            <mat-error *ngIf="contactID.errors.start">
                                <span data-automation="ecrmContactIDStartError"
                                    >{{ 'user.validation.ecrm.contact_id_start' | translate }}
                                </span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry" *ngIf="isEditMode">
                    <!--- ECRM User ID --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.ecrm_id' | translate }}</mat-label>
                        <input
                            matInput
                            id="ecrmID"
                            #ecrmID="ngModel"
                            type="text"
                            class="input-entry"
                            [ngClass]="{ failure: ecrmID.invalid && (ecrmID.dirty || ecrmID.touched) }"
                            placeholder="{{ 'user.ecrm_id' | translate }}"
                            [disabled]="!hasCommunityUserRole()"
                            name="ecrmID"
                            [(ngModel)]="user.ecrm_id"
                            data-url-persist
                            [required]="hasCommunityUserRole()"
                            data-automation="ecrmID"
                            (input)="onECRMUserIDChange()"
                            matTooltip="{{ 'user.tooltip.ecrm_user_id' | translate }}"
                        />
                        <mat-icon
                            svgIcon="general-info"
                            data-automation="ecrmUserHelpIcon"
                            matSuffix
                            class="tooltip-icon mat-select-tooltip"
                            matTooltip="{{ 'user.tooltip.ecrm_user_id' | translate }}"
                        ></mat-icon>
                        <span>{{ spacesAndRoles }}</span>
                        <mat-error *ngIf="ecrmID.invalid && (ecrmID.dirty || ecrmID.touched)">
                            <mat-error *ngIf="ecrmID.errors.required">
                                <span>{{ 'user.validation.required' | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="ecrmID.errors.pattern">
                                <span data-automation="ecrmIDLengthError"
                                    >{{ 'user.validation.ecrm.length' | translate }}
                                </span>
                            </mat-error>
                            <mat-error *ngIf="ecrmID.errors.start">
                                <span data-automation="ecrmIDStartError"
                                    >{{ 'user.validation.ecrm.start' | translate }}
                                </span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- Sync to ECRM --->
                <div>
                    <div class="form-entry">
                        <div class="checkbox-feedback">
                            <span>
                                <mat-checkbox
                                    [disabled]="isDeactivatedUser() || unsafeSubscriptions || (isEditMode && disableSync)"
                                    name="ecrmSync"
                                    data-automation="ecrmSync"
                                    #primaryContact="ngModel"
                                    id="sync_to_ecrm"
                                    [(ngModel)]="user.sync_to_ecrm"
                                    (change)="handleEcrmChange()"
                                    matTooltip="{{ 'user.tooltip.sync_to_ecrm' | translate }}"
                                    >{{ 'user.sync_to_ecrm' | translate }}
                                </mat-checkbox>
                                <mat-icon
                                    svgIcon="general-info"
                                    data-automation="syncToEcrmHelpIcon"
                                    matSuffix
                                    class="tooltip-icon mat-select-tooltip"
                                    matTooltip="{{ 'user.tooltip.sync_to_ecrm' | translate }}"
                                ></mat-icon>
                            </span>
                            
                            <span *ngIf="user.last_sync_date" class="last-synced">
                                {{ 'user.last_sync_date' | translate }} {{ user.last_sync_date | date : 'medium' }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="form-entry">
                    <!--- Address 1 --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.address1' | translate }}</mat-label>
                        <input
                            matInput
                            id="address1"
                            name="address1"
                            #address1="ngModel"
                            (input)="onAddressFieldChange($event)"
                            type="text"
                            class="input-entry"
                            [ngClass]="{ failure: address1.invalid && (address1.dirty || address1.touched) }"
                            [(ngModel)]="user.addresses[0] && user.addresses[0].address_1"
                            placeholder="{{
                                (addressFieldValidation
                                    ? 'user.create.placeholder.address1_required'
                                    : 'user.create.placeholder.address1'
                                ) | translate
                            }}"
                            [required]="addressFieldValidation"
                            data-automation="address1"
                            data-url-persist
                            minlength="1"
                            maxlength="80"
                        />
                        <mat-error
                            *ngIf="
                                (role_error_name == null || hasEcrm == true) &&
                                address1.invalid &&
                                (address1.dirty || address1.touched)
                            "
                        >
                            <mat-error *ngIf="address1.errors.required">
                                <span>{{ 'user.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Address 2 --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.address2' | translate }}</mat-label>
                        <input
                            matInput
                            id="address2"
                            #address2="ngModel"
                            type="text"
                            (input)="onAddressFieldChange($event)"
                            class="input-entry"
                            [ngClass]="{ failure: address2.invalid && (address2.dirty || address2.touched) }"
                            name="address2"
                            data-automation="address2"
                            placeholder="{{ 'user.create.placeholder.address2' | translate }}"
                            maxlength="80"
                            [(ngModel)]="user.addresses[0] && user.addresses[0].address_2"
                            data-url-persist
                        />
                        <mat-error
                            *ngIf="
                                (role_error_name == null || hasEcrm == true) &&
                                address2.invalid &&
                                (address2.dirty || address2.touched)
                            "
                        >
                            <mat-error *ngIf="address2.errors.required">
                                <span>{{ 'user.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- City --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.city' | translate }}</mat-label>
                        <input
                            matInput
                            id="city"
                            #city="ngModel"
                            type="text"
                            (input)="onAddressFieldChange($event)"
                            class="input-entry"
                            [ngClass]="{ failure: city.invalid && (city.dirty || city.touched) }"
                            name="city"
                            data-automation="city"
                            placeholder="{{
                                (addressFieldValidation
                                    ? 'user.create.placeholder.city_required'
                                    : 'user.create.placeholder.city'
                                ) | translate
                            }}"
                            minlength="1"
                            maxlength="80"
                            [(ngModel)]="user.addresses[0] && user.addresses[0].city"
                            data-url-persist
                        />
                        <mat-error
                            *ngIf="
                                (role_error_name == null || hasEcrm == true) &&
                                city.invalid &&
                                (city.dirty || city.touched)
                            "
                        >
                            <mat-error *ngIf="city.errors.required">
                                <span>{{ 'user.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Country --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.country' | translate }}</mat-label>
                        <mat-select
                            id="country"
                            name="country"
                            #country="ngModel"
                            (input)="onAddressFieldChange($event)"
                            class="input-entry create-select"
                            [ngClass]="{ failure: country.invalid && (country.dirty || country.touched) }"
                            [(ngModel)]="user.addresses[0] && user.addresses[0].country"
                            data-automation="country"
                            [required]="addressFieldValidation"
                            (selectionChange)="onCountryChange($event)"
                            placeholder="{{
                                (addressFieldValidation
                                    ? 'user.create.placeholder.country_selector_required'
                                    : 'user.create.placeholder.country_selector'
                                ) | translate
                            }}"
                        >
                            <mat-option>{{ 'user.create.placeholder.country_selector' | translate }}</mat-option>
                            <mat-option *ngFor="let country of countries" [value]="country.id">{{
                                country.name
                            }}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="
                                (role_error_name == null || hasEcrm == true) &&
                                country.invalid &&
                                (country.dirty || country.touched)
                            "
                        >
                            <mat-error *ngIf="country.errors.required">
                                {{ 'user.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- State/Province --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.state' | translate }}</mat-label>
                        <mat-select
                            [disabled]="stateDisable"
                            id="state"
                            name="state"
                            #state="ngModel"
                            (input)="onAddressFieldChange($event)"
                            [required]="addressFieldValidation"
                            class="input-entry create-select"
                            [ngClass]="{ failure: state.invalid && (state.dirty || state.touched) }"
                            [(ngModel)]="user.addresses[0] && user.addresses[0].state_province"
                            data-automation="state"
                            placeholder="{{
                                (addressFieldValidation
                                    ? 'user.create.placeholder.state_selector_required'
                                    : 'user.create.placeholder.state_selector'
                                ) | translate
                            }}"
                        >
                            <mat-option>{{ 'user.create.placeholder.state_selector' | translate }}</mat-option>
                            <mat-option *ngFor="let pr of provinces" [value]="pr.name">{{ pr.name }}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="
                                (role_error_name == null || hasEcrm == true) &&
                                state.invalid &&
                                (state.dirty || state.touched)
                            "
                        >
                            <mat-error *ngIf="state.errors.required">
                                {{ 'user.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <!--- Zip/Postal Code --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'user.zip' | translate }}</mat-label>
                        <input
                            matInput
                            id="zip"
                            #zip="ngModel"
                            type="text"
                            class="input-entry"
                            (input)="onAddressFieldChange($event)"
                            [ngClass]="{ failure: zip.invalid && (zip.dirty || zip.touched) }"
                            name="zip"
                            data-automation="zip"
                            placeholder="{{
                                (addressFieldValidation
                                    ? 'user.create.placeholder.zip_required'
                                    : 'user.create.placeholder.zip'
                                ) | translate
                            }}"
                            [(ngModel)]="user.addresses[0] && user.addresses[0].postal_code"
                            data-url-persist
                        />
                        <mat-error
                            *ngIf="
                                (role_error_name == null || hasEcrm == true) &&
                                zip.invalid &&
                                (zip.dirty || zip.touched)
                            "
                        >
                            <mat-error *ngIf="zip.errors.required">
                                <span>{{ 'user.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel
            togglePosition="before"
            data-automation="user_settings_panel"
            *ngIf="showingUserPreferences"
            [expanded]="collapseArray[3]"
            (opened)="expansionOpened(3)"
            (closed)="expansionClosed(3)"
        >
            <mat-expansion-panel-header
                class="panel-override"
                collapsedHeight="54px"
                expandedHeight="54px"
                data-automation="user_settings_header"
            >
                <mat-panel-title>
                    <h4 class="card-title" data-automation="user_settings">
                        {{ 'user.user_settings' | translate }}
                    </h4>
                </mat-panel-title>
                <mat-panel-description class="user_settings_subtitle"> </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="form-group">
                <div class="form-entry">
                    <div class="mat-form-field-container">
                        <!-- Locale selector-->
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'user.create.platform_locale' | translate }}</mat-label>
                            <mat-select
                                id="locale"
                                name="locale"
                                required
                                #locale="ngModel"
                                class="input-entry create-select"
                                [ngClass]="{ failure: locale.invalid && (locale.dirty || locale.touched) }"
                                [(ngModel)]="user.locale"
                                data-automation="localeSelector"
                                placeholder="{{ 'user.create.placeholder.locale_selector' | translate }}"
                                matTooltip="{{ 'user.tooltip.user_preferences.locale' | translate }}"
                            >
                                <mat-option
                                    *ngFor="let locale of multiLocaleConfig.supportedLocales"
                                    [value]="locale.localeName"
                                >
                                    {{ locale.displayLabel }}
                                </mat-option>
                            </mat-select>

                            <mat-icon
                                svgIcon="general-info"
                                data-automation="userPreferencesLocaleHelpIcon"
                                matSuffix
                                class="tooltip-icon mat-select-tooltip"
                                matTooltip="{{ 'user.tooltip.user_preferences.locale' | translate }}"
                            ></mat-icon>

                            <mat-error *ngIf="locale.invalid && (locale.dirty || locale.touched)">
                                <mat-error *ngIf="locale.errors.required">
                                    {{ 'user.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>

                        <!-- System of measurement selector-->
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'user.create.measurement' | translate }}</mat-label>
                            <mat-select
                                id="measurement"
                                name="measurement"
                                #measurement="ngModel"
                                class="input-entry create-select"
                                [ngClass]="{
                                    failure: measurement.invalid && (measurement.dirty || measurement.touched)
                                }"
                                [(ngModel)]="user.unit_system"
                                data-automation="measurement_systemSelector"
                                required
                                placeholder="{{ 'user.create.placeholder.measurement_selector' | translate }}"
                                matTooltip="{{ 'user.tooltip.measurement' | translate }}"
                            >
                                <mat-option *ngFor="let measurement of measurements" [value]="measurement.name">
                                    {{ measurement.displayLabel }}
                                </mat-option>
                            </mat-select>
                            <mat-icon
                                svgIcon="general-info"
                                data-automation="userPreferencesMeasurementHelpIcon"
                                matSuffix
                                class="tooltip-icon mat-select-tooltip"
                                matTooltip="{{ 'user.tooltip.measurement' | translate }}"
                            ></mat-icon>

                            <mat-error *ngIf="measurement.invalid && (measurement.dirty || measurement.touched)">
                                <mat-error *ngIf="measurement.errors.required">
                                    {{ 'user.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-entry">
                    <div class="mat-form-field-container">
                        <!-- Timezone selector-->
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'user.create.timezone' | translate }}</mat-label>
                            <mat-select
                                id="timezone"
                                name="timezone"
                                #timezone="ngModel"
                                class="input-entry create-select"
                                [ngClass]="{ failure: timezone.invalid && (timezone.dirty || timezone.touched) }"
                                [(ngModel)]="user.timezone"
                                data-automation="timezoneSelector"
                                required
                                placeholder="{{ 'user.create.placeholder.timezone_selector' | translate }}"
                            >
                                <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">
                                    {{ timezone.displayLabel }}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="timezone.invalid && (timezone.dirty || timezone.touched)">
                                <mat-error *ngIf="timezone.errors.required">
                                    {{ 'user.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>

                        <!-- Home Application selector-->
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'user.create.application' | translate }}</mat-label>
                            <mat-select
                                id="application"
                                name="application"
                                #application="ngModel"
                                class="input-entry create-select"
                                [ngClass]="{
                                    failure: application.invalid && (application.dirty || application.touched)
                                }"
                                [(ngModel)]="user.home_application"
                                data-automation="home_applicationSelector"
                                required
                                placeholder="{{ 'user.create.placeholder.application_selector' | translate }}"
                                [disabled]="isCreateMode || (isEditMode && applications.length <= 1)"
                                matTooltip="{{ 'user.tooltip.home_application' | translate }}"
                            >
                                <mat-option *ngFor="let application of applications" [value]="application.name">
                                    {{ application.displayLabel }}
                                </mat-option>
                            </mat-select>

                            <mat-icon
                                svgIcon="general-info"
                                data-automation="userPreferencesHomeAppHelpIcon"
                                matSuffix
                                class="tooltip-icon mat-select-tooltip"
                                matTooltip="{{ 'user.tooltip.home_application' | translate }}"
                            ></mat-icon>

                            <mat-error *ngIf="application.invalid && (application.dirty || application.touched)">
                                <mat-error *ngIf="application.errors.required">
                                    {{ 'user.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</div>
