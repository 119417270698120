import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  ControlContainer,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  NgForm,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { Subscription } from 'rxjs';

import {
  Address,
  ContactSubscriptionPost,
  DefaultAddress,
  DefaultPhoneNumber,
  Phone,
  User,
  UserDefaults,
  UserStatus,
} from '../shared/model/user.model';
import { Application } from '../shared/model/application.model';
import { Locale, LocaleBaseConfig } from '../shared/model/locale.model';
import { Status } from '../shared/model/status.model';
import { Timezone } from '../shared/model/timezone.model';
import { DR_ROLES_TO_SUBSCRIPTIONS, SpaceRole, UserRole } from '../shared/model/business-role.model';
import { Measurement } from '../shared/model/measurement.model';
import { ActiveLocationRolesComponent } from './active-location-roles/active-location-roles.component';

import { UserService } from '../shared/services/user.service';
import { LocalesService } from '../shared/services/locales.service';
import { UserStatusService } from '../shared/services/user-status.service';
import { TimezonesService } from '../shared/services/timezones.service';
import { BusinessRolesService } from '../shared/services/business-roles.service';
import { DeeplinksService } from '../shared/services/deeplinks.service';
import { MeasurementService } from '../shared/services/measurement.service';
import { ApplicationsService } from '../shared/services/applications.service';
import { CountriesService } from '../shared/services/countries.service';
import { UsertypeService } from '../shared/services/usertype.service';
import { BrandsService } from '../shared/services/brands.service';
import { Brand, SYSTEM_USER_BLACKLIST } from '../shared/model/brand.model';
import { GlobalAlertService, GlobalNavService } from 'ngx-global-nav';
import { ContactSubscriptionService } from '../shared/services/contact-subscriptions.service';
import { FormValidatorService } from '../shared/services/form-validator.service';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ItsService } from '../shared/services/its.service';
import { UserUpdateDialogService } from '../shared/services/user-update-dialog.service';
import { UpdateStage } from '../dialogs/update_roles/user-update-dialog.component';
import { LocationRolesComponent } from './location-roles/location-roles.component';
import { SubscriptionTableComponent } from '../shared/components/subscription-table.component';
import { RoleToDelete } from '../dialogs/submit-roles-dialog/submit-roles-dialog.component';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss', '../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class UserFormComponent implements OnInit, OnDestroy {
  readonly EDIT = 'edit';
  readonly CREATE = 'create';

  org = '';
  userId: string;
  brands: Brand[];
  subscriptions: Subscription[] = [];
  locales: Locale[];
  statuses: Status[];
  timezones: Timezone[];
  measurements: Measurement[];
  applications: Application[];
  userRoles: UserRole[];
  spacesWithRoles: SpaceRole[];
  spacesAndRoles: SpaceRole[];
  loggedInUserBrand = '';
  loggedInUserId = '';
  ecrmPattern = /^[A-Za-z0-9]{18}$/;
  syncTooltip = 'user.sync_to_ecrm_tooltip';
  disableSync = true;
  unsafeSubscriptions = true;
  userLocale = 'en_US';
  deeplinkerData: any;
  user: User;
  savedUser: User;
  countries: any[];
  userTypes: any[];
  provinces: any[];
  stateDisable = true;
  loadingLocationsAndRoles = false;
  loadingUserTypes = true;
  loadingBrands = true;
  showingUserPreferences = true;
  hasAnyAddressField = false;
  hasAnyPhoneField = false;
  hasAnyMobilePhoneField = false;
  hasDrUser = false;
  addressFieldValidation: boolean;
  phoneFieldValidation: boolean;
  mobilePhoneFieldValidation: boolean;
  hasEcrm: boolean;
  voice_notification_user = 'DR_VOICE_NOTIFICATIONS_USER';
  sms_notification_user = 'DR_SMS_NOTIFICATIONS_USER';
  email_notification_user = 'DR_EMAIL_NOTIFICATIONS_USER';
  offer_notification_user = 'DR_OFFER_NOTIFICATIONS_USER';
  performance_notification_user = 'DR_PERFORMANCE_NOTIFICATIONS_USER';
  dr_user = 'DR_GENERAL_USER';
  role_error_name: string;
  hasAnyVoiceNotificationRole: boolean;
  hasAnySMSNotificationRole: boolean;
  hasAnyEmailNotificationRole: boolean;
  hasAnyOfferNotificationRole: boolean;
  hasAnyPerformanceNotificationRole: boolean;
  isRetired = false;
  filteredBrands: Brand[] = [];
  filteredUserTypes: any[] = [];
  consumerAdmin = false;
  FAILED_SUBSCRIPTION = 'Oops, Subscription failed to create';
  phone: Phone;
  userStatus: UserStatus;
  removedRoles = 0;
  addedRoles = 0;
  @Input() index = 0;

  private _data: any = {};

  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };

  firstnameLabel: '';
  firstnamePlaceholder: '';
  lastnameLabel: '';
  lastnamePlaceholder: '';
  fullnameLabel: '';
  fullnamePlaceholder: '';

  collapseArray: boolean[];

  @ViewChild(ActiveLocationRolesComponent) ActiveLocationRoles: ActiveLocationRolesComponent;
  @ViewChild(LocationRolesComponent) ConfigureLocationRoles: LocationRolesComponent;
  @ViewChild(SubscriptionTableComponent) SubscriptionTable: SubscriptionTableComponent;
  @ViewChild('address1')
  private address1: ElementRef;

  @ViewChild('country')
  private country: ElementRef;

  @ViewChild('state')
  private state: ElementRef;

  @ViewChild('city')
  private city: ElementRef;

  @ViewChild('zip')
  private zip: ElementRef;

  @ViewChild('phoneNumber')
  private phoneNumber: ElementRef;

  @ViewChild('phoneExtension')
  private phoneExtension: ElementRef;

  @ViewChild('countryCode')
  private phoneCountryCode: ElementRef;

  @ViewChild('mobilePhoneNumber')
  private mobilePhoneNumber: ElementRef;

  @ViewChild('mobileCountryCode')
  private mobilePhoneCountryCode: ElementRef;

  @ViewChild('ecrmID')
  private ecrmUserId: ElementRef;

  @ViewChild('contactID')
  private ecrmContactId: ElementRef;

  erpIconTooltip: MatIconRegistry;

  @Output() onUserClassChange = new EventEmitter<boolean>();

  constructor(
    private ngxCookieService: NgxCookieService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private route: ActivatedRoute,
    private usersService: UserService,
    private localesService: LocalesService,
    private userStatusService: UserStatusService,
    private measurementService: MeasurementService,
    private applicationsService: ApplicationsService,
    private timezonesService: TimezonesService,
    private brandsService: BrandsService,
    private deeplinksInternalService: DeeplinksService,
    private usertypeService: UsertypeService,
    private countriesService: CountriesService,
    private businessRolesService: BusinessRolesService,
    private _adapter: DateAdapter<any>,
    private globalNavService: GlobalNavService,
    private contactSubscriptionService: ContactSubscriptionService,
    private itsService: ItsService,
    private messageService: GlobalAlertService,
    private formValidatorService: FormValidatorService,
    private translateService: TranslateService,
    private form: NgForm,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private userUpdateDialogService: UserUpdateDialogService,
  ) {
    this.org = this.ngxCookieService.getCookie('preferred-org-id') || '';
    this._adapter.setLocale(ngxCookieService.getCookie('locale') || 'en_US');
    this.measurements = measurementService.measurements;
    this.timezones = this.timezonesService.timezones;
    this.countries = this.countriesService.countries$.value;

    this.user = { ...UserDefaults };
    this.user.addresses = [{ ...DefaultAddress }];
    this.user.phones = [{ ...DefaultPhoneNumber }, { ...DefaultPhoneNumber }];
    this.user.phones[1].primary = false;
    this.user.phones[1].phone_type = 'MOBILE';
    this.hasEcrm = this.user.sync_to_ecrm;

    this.erpIconTooltip = iconRegistry.addSvgIcon(
      'general-info',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/general-info.svg'),
    );

    this.collapseArray = new Array(4).fill(true);
  }

  ngOnInit() {
    const routeSub = this.route.params.subscribe(async (params) => {
      if (params.id) {
        this.userId = params.id;
        this.contactSubscriptionService.getContactSubscriptions(this.userId);
        this.user = await this.usersService.getUser(this.userId);
        this.savedUser = { ...this.user };
        this.populateUser(this.user);
      }

      if (this.isCreateMode) {
        this.user.first_names = {};
        this.user.last_names = {};
      }
    });

    this.firstnameLabel = this.translateService.instant('user.first_name');
    this.firstnamePlaceholder = this.translateService.instant('user.create.placeholder.first_name');
    this.lastnameLabel = this.translateService.instant('user.last_name');
    this.lastnamePlaceholder = this.translateService.instant('user.create.placeholder.last_name');
    this.fullnameLabel = this.translateService.instant('user.full_name');
    this.fullnamePlaceholder = this.translateService.instant('user.create.placeholder.full_name');

    const statusesSub = this.userStatusService.statuses$.subscribe((statuses) => (this.statuses = statuses));
    const localesSub = this.localesService.locales$.subscribe((locales) => {
      if (locales?.length) {
        this.locales = locales;
        const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
        this.multiLocaleConfig.defaultLocale = locale;

        if (this.isCreateMode) {
          // Always add en_US
          const supportedLocales = new Set([locales[0], locale]);
          this.multiLocaleConfig.supportedLocales = Array.from(supportedLocales);
          this.user.locale = this.userLocale;
        } else if (this.isEditMode) {
          this.buildSupportedLocales();
        }
      }
    });

    const timezonesSub = this.timezonesService.timezones$.subscribe((timezones) => (this.timezones = timezones));
    const brandsSub = this.brandsService.brands$.subscribe((brands) => {
      this.loadingBrands = false;
      this.brands = brands;
    });

    const countriesSub = this.countriesService.countries$.subscribe((countries) => {
      this.appData.countries = countries;
      this.countries = countries;
      if (this.isEditMode && this.user) this.buildProvinces(this.user.addresses[0].country);
    });

    const userTypesSub = this.usertypeService.userTypes$.subscribe(async (userTypes) => {
      await this.usersService.getLoggedInUser();
      this.userTypes = userTypes;
      this.filteredUserTypes = this.usertypeService.filterTypesByBrand(this.loggedInUserBrand, userTypes);
      if (this.isSystemUser()) {
        this.filteredUserTypes = this.filteredUserTypes.filter((type) => !SYSTEM_USER_BLACKLIST.includes(type.name));
      }
      this.loadingUserTypes = false;
    });

    const loggedInUserSub = this.usersService.loggedInUser$.subscribe(async (loggedInUser: User) => {
      if (loggedInUser) {
        this.loggedInUserBrand = loggedInUser.brand;
        this.loggedInUserId = loggedInUser.userId;
        this.userLocale = loggedInUser.locale;
        await this.localesService.getLocales();
        this.filteredBrands = this.brandsService.getFilteredBrands(this.loggedInUserBrand, this.brands);
        if (this.isCreateMode) this.setUserBrand(this.filteredBrands);
      }
    });

    const userRolesSub = this.businessRolesService.userRoles$.subscribe(({ roles }) => {
      this.userRoles = roles;
      this.checkFilterRoles();
    });

    const spacesAndRolesSub = this.businessRolesService.spacesWithRoles$.subscribe((spacesWithRoles) => {
      this.spacesWithRoles = spacesWithRoles;
    });

    const updateRoleRequirements = this.businessRolesService.updateRoleRequirements$.subscribe(async (spaceRole) => {
      if (this.spacesWithRoles) {
        await this.checkNewRoleSafety(this.spacesWithRoles);
        this.hasAnyVoiceNotificationRole = false;
        this.hasAnySMSNotificationRole = false;
        this.hasAnyEmailNotificationRole = false;
        this.hasDrUser = false;
        this.role_error_name = null;
        this.hasEcrm = this.user.sync_to_ecrm;

        this.spacesWithRoles.forEach((spaces) => {
          const newRole = <any>spaces.newRoles;
          newRole.forEach((role) => {
            this.checkRole(role.name);
          });
        });

        if (this.phoneCountryCode && this.phoneNumber) {
          this.phoneFieldValidationCheck();
        }

        if (this.mobilePhoneCountryCode && this.mobilePhoneNumber) {
          this.mobilePhoneValidationCheck();
        }

        if (this.phoneCountryCode && this.phoneNumber) {
          this.addressFieldValidationCheck();
        }
      }
    });

    const removedSpaceSub = this.businessRolesService.removedSpace$.subscribe((removedSpaceId) => {
      if (removedSpaceId && this.spacesWithRoles && this.spacesWithRoles.length) {
        const index = this.spacesWithRoles.findIndex((space) => space.id === removedSpaceId);
        if (index >= 0) {
          this.spacesWithRoles.splice(index, 1);
        }
        this.checkNewRoleSafety(this.spacesWithRoles);
      }
    });

    const removedRoleSub = this.businessRolesService.removedRole$.subscribe((roles) => {
      this.businessRolesService.userRoles$.subscribe(({ roles }) => {
        this.validateRoleValues(roles);
      });
    });

    const applicationsSub = this.applicationsService.applications$.subscribe((applications) => {
      this.applications = applications;
      if (this.isCreateMode && this.applications.length > 0 && this.user) {
        for (const app of applications) {
          if (app.name === 'PROFILE') {
            this.user.home_application = app.name;
          }
        }
        if (!this.user.home_application) {
          this.user.home_application = applications[0].name;
        }
      }
      if (
        this.user &&
        (!this.user.home_application || !this.applications.find((app) => app.name === this.user.home_application))
      ) {
        this.user.home_application = 'PROFILE';
      }
      this.checkFilterRoles();
    });

    const userRoleSub = this.globalNavService.userRolesList$.subscribe((roles: any) => {
      if (roles) {
        const consumerAdmin = roles.find((role) => role.business_role === 'CONSUMERS_USER_ADMIN') !== undefined;
        const userAdmin = roles.find((role) => role.business_role === 'USER_ADMIN') !== undefined;
        this.consumerAdmin = consumerAdmin && !userAdmin;
        this.checkFilterRoles();
      }
    });

    const businessUserRolesSub = this.businessRolesService.userRoles$.subscribe(async ({ roles }) => {
      if (roles) {
        await this.checkCurrentRoleSafety(roles);
        this.validateRoleValues(roles);
      }
    });

    const userStatusSub = this.userStatusService.userStatus$.subscribe((status) => {
      this.userStatus = status;
    });

    const newSpacesWithRolesSub = this.businessRolesService.newSpacesWithRoles$.subscribe((newSpaceWithRoles) => {
      newSpaceWithRoles.forEach((space) => {
        if (space.newRoles.length > 0) {
          space.newRoles.forEach((role) => {
            this.checkRole(role.name);
          });
        }
      });
      this.checkNewRoleSafety(newSpaceWithRoles);
      this.addNewRoles(newSpaceWithRoles);
    });

    this.measurementService.setMeasurements();
    this.deeplinksInternalService.setMarketAdminUrl();
    if (!this.isCreateMode && this.userId) {
      this.applicationsService.getApplicationsByUser(this.userId);
    } else {
      this.applicationsService.getApplications();
    }
    this.subscriptions.push(
      ...[
        routeSub,
        statusesSub,
        localesSub,
        timezonesSub,
        countriesSub,
        userTypesSub,
        brandsSub,
        loggedInUserSub,
        userRolesSub,
        businessUserRolesSub,
        newSpacesWithRolesSub,
        spacesAndRolesSub,
        updateRoleRequirements,
        removedSpaceSub,
        removedRoleSub,
        applicationsSub,
        userRoleSub,
        userStatusSub,
      ],
    );
  }

  @Input() mode: string;
  @Input() change: any;
  @Input()
  set appData(appData: any) {
    this._data = {
      ...this._data,
      ...appData,
    };
  }

  get appData() {
    return this._data;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get fullName() {
    let name = this.user.username;
    if (this.user.first_names[this.user.locale] && this.user.last_names[this.user.locale]) {
      name = `${this.user.first_names[this.user.locale] || ''} ${this.user.last_names[this.user.locale] || ''}`;
    }
    if (this.user.fullName && name !== this.user.fullName) {
      name = this.user.fullName;
    }
    return name;
  }

  checkFilterRoles() {
    if (this.userRoles && this.applications && this.consumerAdmin) {
      const userIsConsumerAdmin =
        this.userRoles.find((role) => role.businessRole === 'CONSUMERS_USER_ADMIN') !== undefined;
      if (this.consumerAdmin && !userIsConsumerAdmin) {
        this.applications = this.applications.filter((application) => {
          return application.name !== 'GLOBAL_ADMIN_USER_ADMIN';
        });
        if (this.isCreateMode && this.applications.length > 0 && this.user) {
          this.user.home_application = this.applications[0].name;
        }
      }
    }
    if (this.isCreateMode && !this.userRoles && this.applications && this.consumerAdmin) {
      this.applications = this.applications.filter((application) => {
        return application.name !== 'GLOBAL_ADMIN_USER_ADMIN';
      });
    }
    if (this.isCreateMode && this.applications) {
      this.user.home_application = 'PROFILE';
    }
  }

  async checkRoleSafety(subs: any[]) {
    let flag = false;
    if (subs.length > 0) {
      const siteIds = subs.map((sub) => sub.entity_id);
      const mappedSiteIds = await this.itsService.hasEcrmMapping(siteIds);
      for (const sub of subs) {
        if (!mappedSiteIds.includes(sub.entity_id)) {
          flag = true;
        }
      }
    }
    if (flag) {
      //Subscription mapped on NON-ECRM Site
      this.unsafeSubscriptions = true;
      this.syncTooltip = 'user.sync_to_ecrm_unsafe_tooltip';
      this.user.sync_to_ecrm = false;
    } else {
      this.unsafeSubscriptions = false;
      this.syncTooltip = 'user.sync_to_ecrm_tooltip';
    }
  }

  async checkCurrentRoleSafety(roles: any[]) {
    const entities = [];
    const entitySubscriptions = roles.filter((role) => DR_ROLES_TO_SUBSCRIPTIONS.includes(role.businessRole));
    entitySubscriptions.forEach((sub) => {
      sub.entityIds.forEach((entity) => {
        if (entity.spaceType === 'Site') {
          entities.push({
            entity_id: entity.id,
            entity_name: entity.name,
            entity_type: entity.spaceType,
          });
        }
      });
    });
    await this.checkRoleSafety(entities);
  }

  async checkNewRoleSafety(newSpacesWithRoles: any[]) {
    // Check for legality of new roles
    const subs: any[] = [];
    newSpacesWithRoles.forEach((space) => {
      const entitySubscriptions = space.newRoles
        .map((role: any) => role.name)
        .filter((role: string) => DR_ROLES_TO_SUBSCRIPTIONS.includes(role));
      if (entitySubscriptions.length > 0 && space.drType === 'Site') {
        subs.push({
          entity_id: space.id,
          entity_name: space.displayLabel,
          entity_type: space.drType,
          roles: entitySubscriptions,
        });
      }
    });
    await this.checkRoleSafety(subs);
  }

  setUserBrand(brands: Brand[]) {
    this.user.brand = brands.find((item) => item.name === UserDefaults.brand)?.name || this.filteredBrands[0]?.name;
  }

  async applyRoles(state) {
    this.loadingLocationsAndRoles = true;
    if (!state) {
      this.spacesAndRoles = await this.businessRolesService.getUserRoles(this.userId);
      this.loadingLocationsAndRoles = false;
    }
  }

  addNewRoles(newSpaceWithRoles) {
    if (newSpaceWithRoles && newSpaceWithRoles.length) {
      newSpaceWithRoles.forEach((space) => {
        const activeSpace = this.spacesWithRoles.find((activeSpace) => activeSpace.id === space.id);
        if (!activeSpace) {
          this.spacesWithRoles = [...new Set([...this.spacesWithRoles, space])];
        } else {
          const newRoles = space.newRoles.filter(
            (newRole) => !activeSpace.activeRoles.find((activeRole) => activeRole.businessRole === newRole.name),
          );
          activeSpace.newRoles = [...new Set([...activeSpace.newRoles, ...newRoles])];
        }
      });
    }
  }

  hasRole(roles, searchRole) {
    return roles
      .map(({ businessRole, name }: any) => {
        businessRole = businessRole || name;
        return businessRole;
      })
      .includes(searchRole);
  }

  hasCommunityUserRole(): boolean {
    const role = 'COMMUNITY_USER';
    if (this.userRoles && this.userRoles.length && this.hasRole(this.userRoles, role)) {
      return true;
    }
    if (this.spacesWithRoles && this.spacesWithRoles.length) {
      let hasRole = false;
      this.spacesWithRoles.forEach((space) => {
        if (hasRole) {
          return;
        }
        if (space.newRoles) {
          hasRole = this.hasRole(space.newRoles, role) || hasRole;
        }
        if (space.activeRoles) {
          hasRole = this.hasRole(space.activeRoles, role) || hasRole;
        }
      });
      return hasRole;
    }
  }

  populateUser(userData) {
    const defaultAddress: Address = { ...DefaultAddress };
    const defaultPhone: Phone = { ...DefaultPhoneNumber };

    if (this.user.addresses.length > 0) {
      const userCountry = this.user.addresses[0].country;
      if (userCountry.match(/[a-zA-Z]/g)) {
        const country = this.countries.find((country) => country.name === userCountry);
        if (country) {
          this.user.addresses[0].country = country.id;
        } else this.user.addresses[0].country = undefined;
      }
    }

    if (!this.user.addresses.length) {
      this.user.addresses = [defaultAddress];
    }

    if (!this.user.home_application) {
      this.user.home_application = 'PROFILE';
    }

    this.disableSync = this.user.sync_to_ecrm;

    // Seed default phones
    if (!this.user.phones.length) {
      this.user.phones = [{ ...defaultPhone }, { ...defaultPhone }];
      this.user.phones[1].primary = false;
      this.user.phones[1].phone_type = 'MOBILE';
    } else {
      const phones: Phone[] = this.user.phones;
      phones.sort((a, b) => Number(b.primary) - Number(a.primary));
      // User has just mobile phone, seed normal
      if (phones[0].phone_type === 'MOBILE') {
        this.user.phones = [defaultPhone, this.user.phones[1]];
      }
      // User has just a normal phone, seed mobile
      else {
        this.user.phones = [...this.user.phones, defaultPhone];
        this.user.phones[1].primary = false;
        this.user.phones[1].phone_type = 'MOBILE';
      }
    }

    this.buildProvinces(this.user.addresses[0].country);

    if (userData.status && userData.status === 'RETIRED') {
      this.isRetired = true;
    }
    this.onAddressFieldChange(null);
    this.buildSupportedLocales();
    this.handleEcrmChange();
  }

  resetDeeplinkerData() {
    this.deeplinkerData = {};
  }

  getUserTypeForUser() {
    if (this.userTypes && this.userTypes.length > 0 && this.user.userType) {
      return this.userTypes.find((userType) => userType.name === this.user.userType).displayLabel || '';
    }
  }

  private buildProvinces(countryId: string) {
    this.stateDisable = !countryId;
    this.provinces = [];

    if (this.stateDisable) {
      return;
    }

    if (this.countries && this.countries.length > 0) {
      const res = this.countries.find((item) => item.id === countryId, countryId);
      if (res) this.provinces = res.provinces;
    }
  }

  handleEcrmChange() {
    this.hasEcrm = this.user.sync_to_ecrm;

    const addr1Ctrl: UntypedFormControl = (this.address1 as any).control;
    const frm: UntypedFormGroup | UntypedFormArray = addr1Ctrl.parent;
    frm.markAllAsTouched();

    this.addressFieldValidationCheck();
  }

  private validateRoleValues(roles: UserRole[]) {
    this.hasAnyVoiceNotificationRole = false;
    this.hasAnySMSNotificationRole = false;
    this.hasAnyEmailNotificationRole = false;
    this.hasAnyOfferNotificationRole = false;
    this.hasAnyPerformanceNotificationRole = false;
    this.hasDrUser = false;
    this.role_error_name = null;
    this.hasEcrm = this.user.sync_to_ecrm;

    roles.forEach((role) => {
      this.checkRole(role.businessRole);
    });

    if (this.phoneCountryCode && this.phoneNumber) {
      this.phoneFieldValidationCheck();
    }

    if (this.mobilePhoneCountryCode && this.mobilePhoneNumber) {
      this.mobilePhoneValidationCheck();
    }

    if (this.phoneCountryCode && this.phoneNumber) {
      this.addressFieldValidationCheck();
    }
  }

  private checkRole(role: string) {
    if (role === this.voice_notification_user && !this.hasAnyVoiceNotificationRole) {
      this.hasAnyVoiceNotificationRole = true;
    }

    if (role === this.sms_notification_user && !this.hasAnySMSNotificationRole) {
      this.hasAnySMSNotificationRole = true;
    }

    if (role === this.email_notification_user && !this.hasAnyEmailNotificationRole) {
      this.hasAnyEmailNotificationRole = true;
    }

    if (role === this.offer_notification_user && !this.hasAnyOfferNotificationRole) {
      this.hasAnyOfferNotificationRole = true;
    }

    if (role === this.performance_notification_user && !this.hasAnyPerformanceNotificationRole) {
      this.hasAnyPerformanceNotificationRole = true;
    }

    if (role === this.dr_user) {
      this.hasDrUser = true;
    }
  }

  isRoleError() {
    if (this.hasAnyVoiceNotificationRole) {
      this.role_error_name = 'voice notification';
      return this.voice_notification_user;
    }

    if (this.hasAnySMSNotificationRole) {
      this.role_error_name = 'sms notification';
      return this.sms_notification_user;
    }

    if (this.hasEcrm) {
      this.role_error_name = null;
      return this.dr_user;
    }

    this.role_error_name = null;
    return null;
  }

  roleError(role: string) {
    this.checkRole(role);

    if (this.phoneCountryCode && this.phoneNumber) {
      this.phoneFieldValidationCheck();
    }
    if (this.mobilePhoneCountryCode && this.mobilePhoneNumber) {
      this.mobilePhoneValidationCheck();
    }
    if (this.phoneCountryCode && this.phoneNumber) {
      this.addressFieldValidationCheck();
    }

    if (role === this.voice_notification_user) {
      if (!this.hasAnyPhoneField && !this.hasAnyMobilePhoneField) {
        document.getElementById('phone_number').focus();
      }
    }

    if (role === this.sms_notification_user) {
      if (!this.hasAnyMobilePhoneField) {
        document.getElementById('mobile_phone_number').focus();
      }
    }
  }

  onAddressFieldChange($event) {
    this.addressFieldValidationCheck();
  }

  addressFieldValidationCheck() {
    const { address_1 = '', address_2 = '', country = '', city = '', postal_code } = this.user.addresses[0];

    let { state_province = '' } = this.user.addresses[0];

    let isAddrRolesMet = true;

    const isRoleErr = this.isRoleError();

    const postalCodeString = postal_code as unknown as string;
    if (!country) {
      state_province = '';
    }

    if (this.hasEcrm) {
      isAddrRolesMet = false;
    }

    // Not required if all values are true
    const role_check = !(
      (state_province.trim().length === 0 ||
        address_1.length === 0 ||
        address_2.trim().length === 0 ||
        country.trim().length === 0 ||
        city.trim().length === 0) &&
      isAddrRolesMet
    );

    const nowHasAnyAddrField =
      state_province.trim().length > 0 ||
      address_1.length > 0 ||
      address_2.trim().length > 0 ||
      country.trim().length > 0 ||
      city.trim().length > 0 ||
      !(postalCodeString === undefined || postalCodeString === '');

    const nowHasAnyAddrFieldRequired = nowHasAnyAddrField || role_check;

    const addr1Ctrl: UntypedFormControl = (this.address1 as any).control;
    const countryCtrl: UntypedFormControl = (this.country as any).control;
    const stateCtrl: UntypedFormControl = (this.state as any).control;
    const cityCtrl: UntypedFormControl = (this.city as any).control;
    const zip: UntypedFormControl = (this.zip as any).control;
    const frm: UntypedFormGroup | UntypedFormArray = addr1Ctrl.parent;

    if (nowHasAnyAddrFieldRequired) {
      addr1Ctrl.setValidators([Validators.required]);
      addr1Ctrl.updateValueAndValidity();
      countryCtrl.setValidators([Validators.required]);
      countryCtrl.updateValueAndValidity();
      stateCtrl.setValidators([Validators.required]);
      stateCtrl.updateValueAndValidity();
      cityCtrl.setValidators([Validators.required]);
      cityCtrl.updateValueAndValidity();
      zip.setValidators([Validators.required]);
      zip.updateValueAndValidity();
    } else {
      addr1Ctrl.setValidators([]);
      addr1Ctrl.setErrors(null);
      cityCtrl.setValidators([]);
      cityCtrl.setErrors(null);
      countryCtrl.setValidators([]);
      countryCtrl.setErrors(null);
      stateCtrl.setValidators([]);
      stateCtrl.setErrors(null);
      zip.setValidators([]);
      zip.setErrors(null);

      frm.setErrors(null);
    }
    this.hasAnyAddressField = nowHasAnyAddrField;
    this.addressFieldValidation = nowHasAnyAddrFieldRequired;

    frm.markAsDirty();
    frm.updateValueAndValidity();

    this.hasAnyAddressField = nowHasAnyAddrField;
    this.addressFieldValidation = nowHasAnyAddrFieldRequired;
  }

  onPhoneFieldChange($event) {
    if (this.phoneCountryCode && this.phoneNumber) {
      this.phoneFieldValidationCheck();
    }
  }

  phoneFieldValidationCheck() {
    const { phone_number = '', extension = '', country_code = '' } = this.user.phones[0];
    const isRoleErr = this.isRoleError();
    const phoneRoleCheck = isRoleErr === this.voice_notification_user && !this.hasAnyMobilePhoneField;
    const isPhoneRolesMet = !phoneRoleCheck;

    // Not required if all values are true
    const role_check = !((phone_number as unknown as string).length === 0 && isPhoneRolesMet);

    const nowHasPhoneField =
      (phone_number as unknown as string).length > 0 ||
      (extension as unknown as string).length > 0 ||
      (country_code as unknown as string).length > 0;

    const nowHasPhoneFieldRequired = nowHasPhoneField || role_check;

    const countryCodeCtrl = (this.phoneCountryCode as any).control;
    const phoneNumberCtrl = (this.phoneNumber as any).control;
    const frm = countryCodeCtrl.parent;

    if (nowHasPhoneFieldRequired) {
      countryCodeCtrl.setValidators([Validators.required]);
      countryCodeCtrl.updateValueAndValidity();
      phoneNumberCtrl.setValidators([Validators.required]);
      phoneNumberCtrl.updateValueAndValidity();
    } else {
      countryCodeCtrl.setValidators([]);
      countryCodeCtrl.setErrors(null);
      phoneNumberCtrl.setValidators([]);
      phoneNumberCtrl.setErrors(null);
      frm.setErrors(null);
    }

    this.hasAnyPhoneField = nowHasPhoneField;
    this.phoneFieldValidation = nowHasPhoneFieldRequired;

    frm.markAsDirty();
    frm.updateValueAndValidity();

    this.hasAnyPhoneField = nowHasPhoneField;
    this.phoneFieldValidation = nowHasPhoneFieldRequired;

    if (phoneRoleCheck && this.hasAnyPhoneField) {
      this.mobilePhoneValidationCheck();
    }
  }

  onMobilePhoneFieldChange($event) {
    this.mobilePhoneValidationCheck();
  }

  mobilePhoneValidationCheck() {
    const { phone_number = '', extension = '', country_code = '' } = this.user.phones[1];
    let isMobPhoneRolesMet = true;

    const isRoleErr = this.isRoleError();

    const isVoiceTrue = isRoleErr === this.voice_notification_user && !this.hasAnyPhoneField;

    if (this.hasAnySMSNotificationRole || isVoiceTrue) {
      isMobPhoneRolesMet = false;
    }

    // Not required if all values are true
    const role_check = !((phone_number as unknown as string).length === 0 && isMobPhoneRolesMet);

    const nowHasMobilePhoneField =
      (phone_number as unknown as string).length > 0 || (country_code as unknown as string).length > 0;

    const nowHasMobilePhoneFieldRequired = nowHasMobilePhoneField || role_check;
    const mobileCountryCodeCtrl = (this.mobilePhoneCountryCode as any).control;
    const mobilePhoneNumberCtrl = (this.mobilePhoneNumber as any).control;
    const mobileFrm = mobileCountryCodeCtrl.parent;

    if (nowHasMobilePhoneFieldRequired) {
      mobileCountryCodeCtrl.setValidators([Validators.required]);
      mobileCountryCodeCtrl.updateValueAndValidity();
      mobilePhoneNumberCtrl.setValidators([Validators.required]);
      mobilePhoneNumberCtrl.updateValueAndValidity();
    } else {
      mobileCountryCodeCtrl.setValidators([]);
      mobileCountryCodeCtrl.setErrors(null);
      mobilePhoneNumberCtrl.setValidators([]);
      mobilePhoneNumberCtrl.setErrors(null);
      mobileFrm.setErrors(null);
    }

    this.hasAnyMobilePhoneField = nowHasMobilePhoneField;
    this.mobilePhoneFieldValidation = nowHasMobilePhoneFieldRequired;

    mobileFrm.markAsDirty();
    mobileFrm.updateValueAndValidity();

    this.hasAnyMobilePhoneField = nowHasMobilePhoneField;
    this.mobilePhoneFieldValidation = nowHasMobilePhoneFieldRequired;

    if (isVoiceTrue && this.hasAnyMobilePhoneField) {
      if (this.phoneCountryCode && this.phoneNumber) {
        this.phoneFieldValidationCheck();
      }
    }
  }

  onCountryChange($event) {
    this.buildProvinces($event.value);
    this.onAddressFieldChange($event);
  }

  onUserTypeFieldChange() {
    if (this.isCreateMode) this.filterBrandsByType();
  }

  isNotOwnProfile() {
    if (this.userId && this.loggedInUserId) {
      return this.userId !== this.loggedInUserId;
    }
    return false;
  }

  filterBrandsByType() {
    this.filteredBrands = this.brandsService.getFilteredBrands(this.loggedInUserBrand, this.brands);
    this.filteredBrands = this.brandsService.filterBrandsByUserType(this.user.userType, this.filteredBrands);
    this.setUserBrand(this.filteredBrands);
  }

  toggleUserPreferences() {
    this.showingUserPreferences = !this.showingUserPreferences;
  }

  async submitRoles(bulkRoles?: boolean) {
    if (!this.isNotOwnProfile()) {
      return;
    }
    if (bulkRoles !== undefined && bulkRoles === true) this.ActiveLocationRoles.sendUpdatingToDialog();
    else this.ActiveLocationRoles.errorRoles([]);

    const submittedRolesStatus = new Map();
    const errors = [];
    const spaceRolesToSubmit = [];
    const allNewRoles = [];
    let subPromise;

    this.ActiveLocationRoles.newSpacesWithRoles.forEach((space) => {
      this.validateRoleValues(space.newRoles);
      if (this.hasAnyVoiceNotificationRole) {
        return;
      }
      space.newRoles.forEach((role: any) =>
        spaceRolesToSubmit.push(JSON.stringify({ role: role.name, entityId: space.id, entityType: space.spaceType })),
      );
      allNewRoles.push(space);
    });

    this.ActiveLocationRoles.spacesWithRoles
      .filter((space) => space.newRoles.length > 0)
      .forEach((space) => {
        space.newRoles.forEach((role: any) =>
          spaceRolesToSubmit.push(JSON.stringify({ role: role.name, entityId: space.id, entityType: space.spaceType })),
        );
        allNewRoles.push(space);
      });

    try {
      this.userUpdateDialogService.setStage(UpdateStage.updating_roles);
      let i = 0;
      for (const spaceRole of spaceRolesToSubmit) {
        if (bulkRoles !== undefined && bulkRoles === true) {
          this.ActiveLocationRoles.sendProgressToDialog(i);
          i++;
        }

        submittedRolesStatus.set(spaceRole, 'LOADING');
        this.businessRolesService.submittedRolesStatus$.next(submittedRolesStatus);
        const { role, entityId, entityType } = JSON.parse(spaceRole);

        await this.businessRolesService
          .submitRoles(this.userId, entityId, role, entityType, this.user.contact_id)
          .then((values) => {
            submittedRolesStatus.set(spaceRole, 'SUCCESS');
            this.businessRolesService.submittedRolesStatus$.next(submittedRolesStatus);
            console.log('No Errors:', values);
          })
          .catch((err) => {
            submittedRolesStatus.set(spaceRole, 'FAILED');
            err.role = spaceRole;
            errors.push(err);
            this.businessRolesService.submittedRolesStatus$.next(submittedRolesStatus);
            console.log('Could not submit role', err);
          });
      }
      if (bulkRoles !== undefined && bulkRoles === true) {
        this.ActiveLocationRoles.sendProgressToDialog(i);
        i++;
      }
      if (bulkRoles == undefined || bulkRoles === false) {
        const rolesToDelete: RoleToDelete[] = [];
        this.ActiveLocationRoles.activeLocations.forEach((space) => {
          space.spaceWithRoles.rolesToDelete.forEach((role) => {
            rolesToDelete.push({ entity: space.spaceWithRoles, role: role });
          });
        });
        const errorRolesDel: RoleToDelete[] = [];
        for (const role of rolesToDelete) {
          try {
            await this.businessRolesService.deleteRole(this.userId, role.entity.id, role.role.businessRole, '', '');
          } catch (e) {
            errorRolesDel.push(role);
            errors.push(e);
            console.log(e);
          }
        }
        this.ActiveLocationRoles.resetRoles();
        if (errorRolesDel.length > 0) {
          this.ActiveLocationRoles.errorRoles(this.ActiveLocationRoles.errorRoleArray.concat(errorRolesDel));
        }
        this.businessRolesService.removedAllRoles(rolesToDelete.map((role) => role.role));
      }
      this.businessRolesService.getUserRoles(this.userId);
      this.contactSubscriptionService.getContactSubscriptions(this.userId);
      this.applicationsService.getApplicationsByUser(this.userId);
      this.ConfigureLocationRoles.deselectAll();
      if (subPromise) {
        const subErrors = await subPromise;
        if (subErrors.length > 0) {
          errors.push(subErrors);
        }
      }
      if (errors.length > 0) {
        throw errors;
      }
      return;
    } catch (error) {
      console.log(error);
      if (bulkRoles !== undefined && bulkRoles === true) this.ActiveLocationRoles.sendErrorToDialog();
      this.applicationsService.getApplicationsByUser(this.userId);
      this.contactSubscriptionService.getContactSubscriptions(this.userId);
      this.businessRolesService.getUserRoles(this.userId);
      this.businessRolesService.clearNewSpaces$.next(true);
      this.businessRolesService.newSpacesWithRoles$.next(allNewRoles);
      throw error;
    }
  }

  async submitSubs() {
    const subscriptions = this.SubscriptionTable.subscriptionChanges();
    if (subscriptions.length > 0) this.userUpdateDialogService.setStage(UpdateStage.updating_subs);
    const errors = [];
    const subsToAdd: ContactSubscriptionPost[] = [];
    const subsToDel: string[] = [];
    let errorCount = 0;
    let successCount = 0;

    for (const sub of subscriptions) {
      const subToAdd: ContactSubscriptionPost = {
        communication_name: '',
        site_id: sub.entityId,
        entity_type: sub.entityType,
        contact_methods: [],
        primary_ind: this.user.primary_contact,
        twentyfour_seven_ind: this.user.twentyfour_seven_contact,
        sync_to_ecrm: this.user.sync_to_ecrm,
      };
      let auxSub;
      if (sub.sms && sub.sms == 'add') {
        auxSub = { ...subToAdd };
        auxSub.communication_name = 'NOTIFY_SMS';
        auxSub.contact_methods.push('sms');
        subsToAdd.push(auxSub);
      }
      if (sub.email && sub.email == 'add') {
        auxSub = { ...subToAdd };
        auxSub.communication_name = 'NOTIFY_EMAIL';
        auxSub.contact_methods.push('email');
        subsToAdd.push(auxSub);
      }
      if (sub.voice && sub.voice == 'add') {
        auxSub = { ...subToAdd };
        auxSub.communication_name = 'NOTIFY_PHONE';
        auxSub.contact_methods.push('voice');
        subsToAdd.push(auxSub);
      }
      if (sub.offer && sub.offer == 'add') {
        auxSub = { ...subToAdd };
        auxSub.communication_name = 'OFFER_EMAIL';
        !auxSub.contact_methods.some((method: string) => method === 'email')
          ? auxSub.contact_methods.push('email')
          : null;
        subsToAdd.push(auxSub);
      }
      if (sub.performance && sub.performance == 'add') {
        auxSub = { ...subToAdd };
        auxSub.communication_name = 'PERFORMANCE_EMAIL';
        !auxSub.contact_methods.some((method: string) => method === 'email')
          ? auxSub.contact_methods.push('email')
          : null;
        subsToAdd.push(auxSub);
      }

      if (sub.sms && sub.sms == 'del') {
        subsToDel.push(sub.smsSubscriptionId);
      }
      if (sub.email && sub.email == 'del') {
        subsToDel.push(sub.emailSubscriptionId);
      }
      if (sub.voice && sub.voice == 'del') {
        subsToDel.push(sub.voiceSubscriptionId);
      }
      if (sub.offer && sub.offer == 'del') {
        subsToDel.push(sub.offerSubscriptionId);
      }
      if (sub.performance && sub.performance == 'del') {
        subsToDel.push(sub.performanceSubscriptionId);
      }
    }
    if (!this.user.flex_contact_id) {
      console.log('No contact id');
      errors.push('No contact id');
      throw errors;
    }

    const totalSubs = subsToAdd.length + subsToDel.length;
    const subscriptionProgress = `\nFailed: ${errorCount} - Succeeded: ${successCount} - Total: ${totalSubs}`;
    let i = 0;
    this.userUpdateDialogService.setSubscriptionProgress(subscriptionProgress);
    for (const sub of subsToAdd) {
      try {
        await this.contactSubscriptionService.postContactSubscription(this.user.flex_contact_id, sub);
        i++;
        successCount++;
        this.userUpdateDialogService.setSubscriptionProgress(
          `\nFailed: ${errorCount} - Succeeded: ${successCount} - Total: ${totalSubs}`,
        );
      } catch (e) {
        console.log('Could not add subscription', e);
        errors.push(e);
        errorCount++;
        this.userUpdateDialogService.setSubscriptionProgress(
          `\nFailed: ${errorCount} - Succeeded: ${successCount} - Total: ${totalSubs}`,
        );
      }
    }
    for (const sub of subsToDel) {
      try {
        await this.contactSubscriptionService.deleteContactSubscription(this.user.flex_contact_id, sub);
        i++;
        successCount++;
        this.userUpdateDialogService.setSubscriptionProgress(
          `\nFailed: ${errorCount} - Succeeded: ${successCount} - Total: ${totalSubs}`,
        );
      } catch (e) {
        console.log('Could not delete subscription', e);
        errors.push(e);
        errorCount++;
        this.userUpdateDialogService.setSubscriptionProgress(
          `\nFailed: ${errorCount} - Succeeded: ${successCount} - Total: ${totalSubs}`,
        );
      }
    }
    if (errors.length > 0) {
      await this.contactSubscriptionService.getContactSubscriptions(this.userId);
      throw errors;
    }
  }

  async newSubmitRoles() {
    this.ActiveLocationRoles.sendUpdatingToDialog();
    if (!this.isNotOwnProfile()) {
      return;
    }
    const submittedRolesStatus = new Map();

    const rolesToSubmit: any[] = [];
    const errorRolesAdd: RoleToDelete[] = [];

    this.ActiveLocationRoles.activeLocations.forEach((location) => {
      this.validateRoleValues(location.spaceWithRoles.newRoles);
      if (this.hasAnyVoiceNotificationRole) {
        return;
      }
      rolesToSubmit.push(
        ...location.spaceWithRoles.newRoles.map((role) => {
          return { role: role, entity: location.spaceWithRoles };
        }),
      );
    });

    let i = 0;
    for (const role of rolesToSubmit) {
      submittedRolesStatus.set(role.role, 'LOADING');
      this.businessRolesService.submittedRolesStatus$.next(submittedRolesStatus);

      this.ActiveLocationRoles.sendProgressToDialog(i);
      i++;

      try {
        await this.businessRolesService.submitRoles(
          this.userId,
          role.entity.id,
          role.role.name,
          role.entity.spaceType,
          this.user.contact_id,
        );
        submittedRolesStatus.set(role.role, 'SUCCESS');
        this.businessRolesService.submittedRolesStatus$.next(submittedRolesStatus);
      } catch (e) {
        submittedRolesStatus.set(role.role, 'FAILED');
        errorRolesAdd.push(role);
        this.businessRolesService.submittedRolesStatus$.next(submittedRolesStatus);
        console.log('Could not submit role', e);
      }
    }
    this.ActiveLocationRoles.sendProgressToDialog(i);
    this.ActiveLocationRoles.resetRoles();
    if (errorRolesAdd.length > 0) {
      this.ActiveLocationRoles.errorRoles(this.ActiveLocationRoles.errorRoleArray.concat(errorRolesAdd));
      this.ActiveLocationRoles.sendErrorToDialog();
    } else {
      this.ActiveLocationRoles.sendSuccessToDialog();
    }
    await this.businessRolesService.getUserRoles(this.userId);
    if (errorRolesAdd.length > 0) {
      this.addedRoles = errorRolesAdd.length;
      this.ActiveLocationRoles.addNewRoles(errorRolesAdd);
    }
    this.contactSubscriptionService.getContactSubscriptions(this.userId);
    this.applicationsService.getApplicationsByUser(this.userId);
    this.ConfigureLocationRoles.deselectAll();
  }

  buildSupportedLocales() {
    if (this.multiLocaleConfig.supportedLocales && this.user && this.locales) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this.user.first_names);
      for (const key of keys) {
        const localeFound = this.locales.find((locale) => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  onSupportedLocaleChange() {
    if (this.multiLocaleConfig.supportedLocales.length === 0) {
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    } else if (this.multiLocaleConfig.supportedLocales.length === 1) {
      this.formValidatorService.resetFieldDefaultLocaleField(
        this.form.form['controls']['app-details']['controls'].defaultLocale,
      );
    }
    this.form.form.controls['localeInputsForm_user_first_name']?.markAsDirty();
    this.form.form.controls['localeInputsForm_user_last_name']?.markAsDirty();
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
      (this.multiLocaleConfig.supportedLocales.length > num - 1 &&
        !this.multiLocaleConfig.supportedLocales.includes(locale)) ||
      locale.id === '1'
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  isEcrmContactEnabled() {
    return this.user.sync_to_ecrm && this.user.last_sync_date !== undefined;
  }

  isDeactivatedUser() {
    return this.user.status.toUpperCase() === 'DEACTIVATED';
  }

  expandAll() {
    console.log('expand all');
    this.collapseArray = new Array(this.collapseArray.length).fill(true);
  }

  collapseAll() {
    this.collapseArray = new Array(this.collapseArray.length).fill(false);
  }

  expansionOpened(i) {
    this.collapseArray[i] = true;
  }

  expansionClosed(i) {
    this.collapseArray[i] = false;
  }

  onRoleRemoval($event) {
    switch ($event) {
      case 'new':
        this.addedRoles -= 1;
        break;
      case 'resetNew':
        this.addedRoles = 0;
        this.removedRoles = 0;
        this.ConfigureLocationRoles.deselectAll();
        break;
      default:
        this.removedRoles += 1;
    }
  }

  onSiteRemoval($event) {
    this.addedRoles -= $event[0];
    this.removedRoles = 0;
    let delroles = 0;
    setTimeout(() => {
      this.ActiveLocationRoles.activeLocations.forEach((space) => {
        delroles += space.roleChips.filter((chip) => chip.toDelete).length;
      });
      this.removedRoles = delroles;
    }, 10);
  }

  //Counts the new roles added to the user.
  onRoleAdd() {
    let newRoles = 0;
    setTimeout(() => {
      this.ActiveLocationRoles.activeLocations.forEach((space) => {
        newRoles += space.spaceWithRoles.newRoles.length;
      });
      this.addedRoles = newRoles;
    }, 100);
  }

  onSystemLevelChange($event) {
    if ($event.checked) {
      this.user.userClass = 'SYSTEM';
      this.onUserClassChange.emit(true);
      this.filteredUserTypes = this.filteredUserTypes.filter((type) => !SYSTEM_USER_BLACKLIST.includes(type.name));
      if (this.user.userType && SYSTEM_USER_BLACKLIST.includes(this.user.userType)) {
        this.user.userType = null;
      }
    } else {
      this.user.userClass = 'PERSON';
      this.usertypeService.getUserTypes();
      this.onUserClassChange.emit(false);
    }
  }

  isSystemUser() {
    return this.user.userClass === 'SYSTEM';
  }

  unRemoveRole($event) {
    this.removedRoles -= $event;
  }

  addSubscription(sub) {
    switch (sub) {
      case 'sms':
        this.hasAnySMSNotificationRole = true;
        if (this.mobilePhoneCountryCode && this.mobilePhoneNumber) {
          this.mobilePhoneValidationCheck();
        }
        break;
      case 'email':
        this.hasAnyEmailNotificationRole = true;
        break;
      case 'voice':
        this.hasAnyVoiceNotificationRole = true;
        if (this.phoneCountryCode && this.phoneNumber) {
          this.phoneFieldValidationCheck();
        }
        break;
      case 'offer':
        this.hasAnyOfferNotificationRole = true;
        break;
      case 'performance':
        this.hasAnyPerformanceNotificationRole = true;
        break;
    }
  }

  removeSubscription(sub) {
    switch (sub) {
      case 'sms':
        this.hasAnySMSNotificationRole = false;
        this.mobilePhoneValidationCheck();
        break;
      case 'email':
        this.hasAnyEmailNotificationRole = false;
        break;
      case 'voice':
        this.hasAnyVoiceNotificationRole = false;
        if (this.phoneCountryCode && this.phoneNumber) {
          this.phoneFieldValidationCheck();
        }
        break;
      case 'offer':
        this.hasAnyOfferNotificationRole = false;
        break;
      case 'performance':
        this.hasAnyPerformanceNotificationRole = false;
        break;
    }
  }

  onECRMContactIDChange() {
    const ecrmContactValidator = (this.ecrmContactId as any).control;

    ecrmContactValidator.setValidators([Validators.pattern(this.ecrmPattern), this.startsWithValidator('003'), Validators.required]);
    ecrmContactValidator.updateValueAndValidity();
  }

  onECRMUserIDChange() {
    const ecrmContactValidator = (this.ecrmUserId as any).control;

    ecrmContactValidator.setValidators([Validators.pattern(this.ecrmPattern), this.startsWithValidator('005'), Validators.required]);
    ecrmContactValidator.updateValueAndValidity();
  }

  startsWithValidator(prefix: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || control.value.startsWith(prefix)) {
        return null; // Valid if empty or starts with the prefix
      }
      return { start: true }; // Error if it doesn't start with the prefix
    };
  }
}
